<template>
  <div class="stat">
    <div class="graph">
      <div class="circle"></div>
      <div class="progress" :data-percentage="convertDecToPercent(stat.data.current / stat.data.full)">
        <span class="progress-left">
          <span class="progress-bar"></span>
        </span>
        <span class="progress-right">
          <span class="progress-bar"></span>
        </span>
        <div class="progress-value">
          <div>
            <div class="value">
              {{ stat.data.before + formatNumberWithCommas(stat.data.current) + ' ' + stat.data.after }}
            </div>
            <div v-if="stat.data.full > 0" class="sub">
              {{ convertDecToPercent(stat.data.current / stat.data.full) }}{{ translations.tcPercentageOfAnnualGoal }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <h4>
      <p href="#">{{ title }}</p>
    </h4>
  </div>
</template>

<script>
/* eslint-disable */
import { translationMixin } from '@/mixins/translationMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [translationMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'graph': {
            tcPercentageOfAnnualGoal: '% of Annual Goal',
          },
        }
      },
    },
    stat: Object,
  },
  data() {
    return {
      translations: {},
    }
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
    title () {
      const propsTitle = this.stat.title.toLowerCase()
      if (propsTitle === 'scripture orders') {
        return this.translations.tcScriptureOrders
      } else if (propsTitle === 'new members') {
        return this.translations.tcNewMembers
      } else if (propsTitle === 'scripture receipts') {
        return this.translations.tcScriptureReceipts
      }
      return ''
    },
  },
  methods: {
    convertDecToPercent(val) {
      return Math.floor(val * 100)
    },
    formatNumberWithCommas(x) {
      if (!x) return 0
      var parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    },
  },
  async created () {
    await this.getComponentTranslations('graph')
      .then(results => {
        this.$set(this, 'translations', results['graph'])
      })
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/components/component-graph.scss';

.progress .progress-value .sub {
  max-width: 250px;
}
</style>
