<template>
  <div class="church-ministry template-1">
    <page-header class="mx-lg">
      <h1>{{ title }}</h1>
    </page-header>
    <camp-selector @camp_changed="page_load($event)" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components" />
    <div class="page-body container mx-med">
      <section class="announcements">
        <div class="container">
          <div class="row">
            <div class="card col">
              <h4>{{ translations.tcAnnouncements }}</h4>
              <div class="ann-text" v-html="announcementsall.msg_message"></div>
              <div class="row btn-row">
                <b-button to="/get-connected/stay-informed/announcements" variant="secondary">
                  {{ translations.tcSeeMore }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="page-body container mx-med pt-3">
      <section class="section-3">
        <div class="row">
          <InfoCard v-for="(info, index) in infocards" :key="index" :obj="info" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import Event from '@/components/Event.vue'
import Graph from '@/components/Graph.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'committee-landing-page',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      yearataglance: null,
      title: '',
      titleTranslationKey: '',
      stats: [],
      visits: [],
      infocards: [],
      dataincircle: [],
      campVisitationInfo: [
        {
          class: 'col col-6',
          title: '909 Camp Assignments',
          content: 'View/manage camp visitation assignments.',
          link: '/officers/country/camp-visitation',
          label: 'View More',
          img: { src: 'svgs/visit-icon.svg', alt: 'Hand Praying', width: 47, height: 64 },
        },
      ],
      churchMinistryInfo: [
        {
          class: 'col col-6',
          title: 'COMMITTEE ROSTER',
          content: 'View a list of all committee members.',
          link: '/officers/page/officers-committe-position',
          label: 'View More',
          img: { src: 'svgs/people.svg', alt: 'Hand Praying', width: 47, height: 64 },
        },
        {
          class: 'col col-6',
          title: 'COMMITTEE REPORT',
          content: 'Run on-demand committee reports such as mailing labels, telephone lists, etc.',
          link: '/reports/report-center/cr',
          label: 'View More',
          img: { src: 'svgs/church-reports.svg', alt: 'Hand Praying', width: 47, height: 64 },
        },
        {
          class: 'col col-12',
          title: 'SECURE REPORT',
          content: 'Run receipt, placement, member and other reports for various states/countries.',
          link: '/reports/report-center/sr',
          label: 'View More',
          img: { src: 'svgs/church-reports.svg', alt: 'Hand Praying', width: 47, height: 64 },
        },
      ],
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let committtee = to.path.split('/')
      let val = to.name !== 'committee-landing-page' ? committtee[committtee.length - 2] : vm.committeeKey
      vm.setCommitteeType(val)
      vm.loadPageDisplay(val)
      vm.loadTranslations()
    })
  },
  methods: {
    ...mapActions({
      getAnnouncementForCommittee: 'announcements/getAnnouncementForCommittee',
      retrieveVideoVisitDetails: 'card/retrieveVideoVisitDetails',
      setCommitteeType: 'officersModule/setCommitteeType',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedMeeting: 'eventCalendar/setSelectedMeeting',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
    }),
    async page_load(evt) {
      if (!evt) {
        try {
          this.setLoadingStatus(true)
          await this.getAnnouncementForCommittee({ cmt_key: this.committeeKey, ind_key: this.userId })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    loadPageDisplay(key) {
      let committee_code = this.$route.path.split('/')[4]
      let committee = constantData.regional_committees.find((rc) => rc.key === key)
      let ttl = constantData.committees.find((cp) => cp.code === committee_code)
      let titleTranslationKeyTmp = !!ttl ? ttl.i18nKey : 'tcCommitteeHome'
      if (['icc', 'ioc'].includes(committee_code)) {
        this.titleTranslationKey = !!committee ? committee.i18nKey : titleTranslationKeyTmp
        this.churchMinistryInfo[2].class = 'col col-6'
        this.infocards = [...this.churchMinistryInfo, ...this.campVisitationInfo]
      } else {
        this.titleTranslationKey = titleTranslationKeyTmp
        this.infocards = [...this.churchMinistryInfo]
      }
    },
    loadTranslations() {
      this.title = this.translations[this.titleTranslationKey]
      this.campVisitationInfo[0].title = this.translations.tc909CampAssignmentsTitle
      this.campVisitationInfo[0].content = this.translations.tc909CampAssignmentsContent
      this.campVisitationInfo[0].label = this.translations.tcViewMore
      this.churchMinistryInfo[0].title = this.translations.tcCommitteeRosterTitle
      this.churchMinistryInfo[0].content = this.translations.tcCommitteeRosterContent
      this.churchMinistryInfo[0].label = this.translations.tcViewMore
      this.churchMinistryInfo[1].title = this.translations.tcCommitteeReportTitle
      this.churchMinistryInfo[1].content = this.translations.tcCommitteeReportContent
      this.churchMinistryInfo[1].label = this.translations.tcViewMore
      this.churchMinistryInfo[2].title = this.translations.tcSecureReportTitle
      this.churchMinistryInfo[2].content = this.translations.tcSecureReportContent
      this.churchMinistryInfo[2].label = this.translations.tcViewMore
    },
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      announcementForCommittee: 'announcements/announcementForCommittee',
      committeeKey: 'officersModule/committeeTypeKey',
      prefCulture: 'user/userPreferredCulture',
      userCountryObject: 'user/userCountryObject',
      userId: 'user/userId',
    }),
    announcementsall() {
      let tan =
        !!this.announcementForCommittee && !!this.announcementForCommittee.msg_message
          ? this.announcementForCommittee
          : { msg_message: this.translations.tcNoAnnouncements || 'No Announcements' }
      tan.msg_message = tan.msg_message.replace(/[\n\r]/g, '<br/>')
      return tan
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([this.getViewTranslations(), this.getComponentTranslations('camp-select')]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    appEvent: Event,
    appGraph: Graph,
    campSelector: CampSelect,
    InfoCard: InfoCard,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.statelanding {
  text-align: center;
}

.church-ministry {
  .page-body {
    margin-top: 68px;

    @include breakpoint(sm) {
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 36px;
    }
  }

  .btn {
    padding: 12px 35px;
    border: none;
    border-radius: 0;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .btn.btn-primary:hover,
  .btn.btn-primary:active,
  .btn.btn-secondary {
    background-color: #fbc301;
  }

  .btn.btn-primary {
    background-color: #003946;
  }

  .ann-text {
    color: #747474;
    font-family: 'Lora', serif;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 36px;
    margin-bottom: 42px;
  }

  .section-1,
  .section-2 {
    margin-bottom: 80px;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }

    >.row {
      margin-bottom: 28px;
    }

    .col {
      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;
      }

      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .more {
    width: auto !important;
    margin: 30px;
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
